import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="NursingExamPro"
      viewBox="0 0 512 512"
    >
      <path
        fill="#4caf50"
        d="M0 256C0 114.7 114.48.13 255.75 0 395.09-.13 509.57 112.19 512 251.51a255.53 255.53 0 01-96.28 204.6 4.88 4.88 0 01-6 .05 256.07 256.07 0 00-307.78.26 4.29 4.29 0 01-5.26 0A255.55 255.55 0 010 256z"
      ></path>
      <path
        fill="#a5d6a7"
        d="M452 256c0 108.25-87.75 196-196 196S60 364.25 60 256 147.75 60 256 60s196 87.75 196 196z"
      ></path>
      <path
        fill="#fff"
        d="M470 290a7.5 7.5 0 01-7.5 7.5h-5v5a7.5 7.5 0 01-15 0v-5h-5a7.5 7.5 0 010-15h5v-5a7.5 7.5 0 0115 0v5h5a7.5 7.5 0 017.5 7.5z"
      ></path>
      <path
        fill="#fff5f5"
        d="M477.5 217.5a7.5 7.5 0 01-7.5 7.5h-5v5a7.5 7.5 0 01-15 0v-5h-5a7.5 7.5 0 010-15h5v-5a7.5 7.5 0 0115 0v5h5a7.5 7.5 0 017.5 7.5z"
      ></path>
      <path
        fill="#1a237e"
        d="M136 123.33a50 50 0 0150-50h50v170a50 50 0 01-50 50h-40a10 10 0 01-10-10z"
      ></path>
      <path
        fill="#303f9f"
        d="M366 293.33h-40a50 50 0 01-50-50v-170h50a50 50 0 0150 50v160a10 10 0 01-10 10zM416 383.33v72.52A254.89 254.89 0 01256 512c-5.37 0-10.71-11.67-16-12a254.78 254.78 0 01-144-55.66v-61a70 70 0 0170-70h12.6l20.79 7.35a170.48 170.48 0 00113.25.08l21.14-7.43H346a70 70 0 0170 70z"
      ></path>
      <path
        fill="#3f51b5"
        d="M241.86 377l-63.64-63.64H196l5.63 1.4A225.82 225.82 0 00316 313.33h17.78L270.14 377a20 20 0 01-28.28 0z"
      ></path>
      <path
        fill="#faa68e"
        d="M146 223.33a25 25 0 01-25-25v-10a25 25 0 0150 0v10a25 25 0 01-25 25z"
      ></path>
      <path
        fill="#ffbeaa"
        d="M316 313.33l-52.93 52.93a10 10 0 01-14.14 0L196 313.33h30V274c0-16.35 12.82-30.17 29.16-30.61a30 30 0 0130.84 30v40z"
      ></path>
      <path
        fill="#faa68e"
        d="M391 188.33v10a25 25 0 01-25 25 25.3 25.3 0 01-7.11-1 110 110 0 01-212.89-39v-40a110 110 0 11220 0v20a25 25 0 0125 25z"
      ></path>
      <path
        fill="#ffbeaa"
        d="M366 143.33v40a110 110 0 01-81.22 106.2 52.8 52.8 0 01-27.56 0A110.06 110.06 0 01176 183.33v-40a110 110 0 0195-109 110 110 0 0195 109z"
      ></path>
      <path
        fill="#784132"
        d="M223.5 150.83a15 15 0 11-15-15 15 15 0 0115 15zm80-15a15 15 0 1015 15 15 15 0 00-15-15z"
      ></path>
      <path
        fill="#fff5f5"
        d="M226 143.33a7.5 7.5 0 11-7.5-7.5 7.5 7.5 0 017.5 7.5zm87.5-7.5a7.5 7.5 0 107.5 7.5 7.5 7.5 0 00-7.5-7.5z"
      ></path>
      <path
        fill="#303f9f"
        d="M376 123.33h-90a50 50 0 01-50-50v-40h50a90 90 0 0190 90z"
      ></path>
      <path
        fill="#1a237e"
        d="M226 33.33h34.75v40a50 50 0 01-50 50H136a90 90 0 0190-90zM130 475.9a256.7 256.7 0 01-34-23v-69.57a70 70 0 0170-70h12.6A70 70 0 00130 380zM348.25 420a7.5 7.5 0 00-7.5 7.5v70.14q7.62-2.67 15-5.8V427.5a7.5 7.5 0 00-7.5-7.5zm-177 7.5v66.14q-7.6-2.67-15-5.8V427.5a7.5 7.5 0 0115 0z"
      ></path>
      <path
        fill="#c8d2dc"
        d="M285.68 424.12a7.52 7.52 0 010-10.61L367 332.2a7.5 7.5 0 0110.61 10.6l-81.32 81.32a7.5 7.5 0 01-10.61 0z"
      ></path>
      <path
        fill="#7cd2e3"
        d="M308.67 404.67l-3.54-3.53a7.5 7.5 0 010-10.61L337 358.71a7.5 7.5 0 0110.61 0l3.53 3.54a7.49 7.49 0 010 10.6l-31.82 31.82a7.49 7.49 0 01-10.65 0z"
      ></path>
      <path
        fill="#fff5f5"
        d="M239.9 469.9a15 15 0 010-21.21l67-67a15 15 0 0121.21 21.22l-67 67a15 15 0 01-21.22 0z"
      ></path>
      <path
        fill="#a6e0ec"
        d="M296.14 473.47a7.5 7.5 0 01-10.61 0l-49.19-49.2a7.5 7.5 0 0110.6-10.61l49.2 49.2a7.5 7.5 0 010 10.61z"
      ></path>
      <path
        fill="#dce6eb"
        d="M293.08 415.35A7.5 7.5 0 01282.47 426l-9.9-9.91 10.61-10.6zm19-19l-9.9-9.9L291.59 397l9.9 9.9a7.5 7.5 0 1010.61-10.6z"
      ></path>
      <path
        fill="#ffc14f"
        d="M262.08 511.93q-3 .07-6.08.07a257.84 257.84 0 01-37.93-2.79A10 10 0 01215 502v-40a10 10 0 0110-10h35a30 30 0 012.08 59.93z"
      ></path>
      <path
        fill="#fff04a"
        d="M215 480v-18a10 10 0 0110-10h35a30 30 0 0127.92 19A30 30 0 01260 490h-35a10 10 0 01-10-10z"
      ></path>
      <path
        fill="#3f51b5"
        d="M96 452h130v58.26a254.8 254.8 0 01-130-54.41z"
      ></path>
      <path
        fill="#1a237e"
        d="M152 490h74v20.26A254.29 254.29 0 01152 490z"
      ></path>
      <path
        fill="#3f51b5"
        d="M230 453.5v57.2q-7.58-.76-15-2v-55.2a7.5 7.5 0 0115 0z"
      ></path>
      <path
        fill="#fff5f5"
        d="M288.28 174.12l58.19 14a15.44 15.44 0 0111.79 15.05c0 50.23-40.36 91-90.14 91h-24.24c-49.78 0-90.14-40.72-90.14-91a15.43 15.43 0 0111.79-15.05l58.19-14a137.95 137.95 0 0164.56 0z"
      ></path>
      <path
        fill="#dce6eb"
        d="M280.89 293.19a89.69 89.69 0 01-12.77.91h-24.24c-49.78 0-90.14-40.72-90.14-91a15.44 15.44 0 0111.79-15.05l8.7-2.09c1.4 57.63 48.13 104.28 106.66 107.18z"
      ></path>
      <path
        fill="#dce6eb"
        d="M173.81 236.37l-9-69.42a27.5 27.5 0 10-54.54 7.1l7.1 54.54A22.53 22.53 0 00142.55 248l24.79-3.23a7.5 7.5 0 006.47-8.4zm-33.2-3.25a7.49 7.49 0 01-8.4-6.46l-7.1-54.54a12.5 12.5 0 1124.79-3.23l8.07 62z"
      ></path>
      <path
        fill="#fff5f5"
        d="M379.92 143.23A27.53 27.53 0 00349.1 167l-9 69.42a7.51 7.51 0 006.47 8.4l24.75 3.18a22.53 22.53 0 0025.22-19.41l7.1-54.54a27.53 27.53 0 00-23.72-30.82zm8.85 28.89l-7.1 54.54a7.51 7.51 0 01-8.41 6.46l-17.36-2.25 8.07-62a12.5 12.5 0 1124.79 3.23z"
      ></path>
      <path
        fill="#dce6eb"
        d="M301.4 247.15c-25.46 11-65.34 11-90.8 0a7.5 7.5 0 013-14.39 7.63 7.63 0 013 .61c21.38 9.23 57.52 9.23 78.91 0a7.5 7.5 0 015.94 13.78zm-84.37-30c21.85 9.42 56.09 9.42 77.94 0a7.5 7.5 0 00-5.97-13.78c-17.9 7.73-48.15 7.73-66.06 0a7.59 7.59 0 00-3-.61 7.5 7.5 0 00-3 14.39z"
      ></path>
      <path
        fill="#faa68e"
        d="M391 198.63a24.92 24.92 0 01-8.08 18.4 66.34 66.34 0 012.84-33.32l2.37-6.72a24.88 24.88 0 012.87 11.64zM146 223.33a25 25 0 01-25-25v-10a25 25 0 0125-25 25 25 0 00-10 20v20a25 25 0 0010 20z"
      ></path>
    </svg>
  );
}

export default Logo;