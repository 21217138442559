import React from "react"
import style from "./style.module.scss"
import Logo from "@/components/Logo"
import { Link } from "gatsby"
import { Button } from 'react-bootstrap'

function switchTheme() {
  let theme = window.localStorage.getItem("data-kit-theme") || ""
  document.querySelector("html").setAttribute("data-kit-theme", theme)
  let attr = document.querySelector("html").getAttribute("data-kit-theme")
  if (attr === "dark") {
    document.querySelector("html").setAttribute("data-kit-theme", "")
    window.localStorage.setItem("data-kit-theme", "")
  } else {
    document.querySelector("html").setAttribute("data-kit-theme", "dark")
    window.localStorage.setItem("data-kit-theme", "dark")
  }
}

export default () => {
  return (
    <div className={style.menu}>
      <div className="op__block">
        <div className={style.menu__inner}>
          <div className={style.menu__logo__container}>
            <div className={style.menu__logo}>
              <Link to="/"><Logo /></Link>
              <div className={style.menu__logo__name}>
                <Link to="/">NursingExamPro.com</Link>
              </div>
            </div>
          </div>
          <div className={style.menu__links}>
            <Link to="/">Home</Link>
            <Link to="/#pricing">Pricing</Link>
            {/* <Link to="/privacy/">Privacy Policy</Link> */}
            <Button variant="flat" className={style.menu__btn__theme} onClick={switchTheme}>
              <i className="fe fe-moon" />
            </Button>
            <a href="https://app.nursingexampro.com/#/auth/login" rel="noopener noreferrer">
              <Button className={style.menu__btn__login}>Login</Button>
            </a>
            <a href="https://app.nursingexampro.com/#/auth/register" rel="noopener noreferrer">
              <Button className={style.menu__btn__signup}>Sign Up</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
