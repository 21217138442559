import React from "react"
import { Link } from "gatsby"
import style from "./style.module.scss"
import Logo from "@/components/Logo"

export default () => {
  return (
    <div className="op__block mb-4">
      <div className="container-fluid">
        <footer className={style.footer}>
          <div className="row">
            <div className="col-lg-12">
              <div className={style.container}>
                <div>
                  <div className="mb-3">
                    <div className={style.logo}>
                      <Logo />
                      <div className={style.name}>NursingExamPro.com</div>
                    </div>
                  </div>
                  <div>
                    Copyright © {new Date().getFullYear()} NursingExamPro.com<br />
                    <Link to="/privacy/">Privacy Policy</Link>{" "}|{" "}
                    <Link to="/terms/">Terms of Use</Link>{" "}|{" "}
                    <a href='mailto:support@nursingexampro.com'>support@nursingexampro.com</a>
                  </div>
                </div>
                <div className={style.ratingWrapper}>
                  <div
                    className={style.rating}
                    itemProp="aggregateRating"
                    itemScope=""
                    itemType="http://schema.org/AggregateRating"
                  >
                    <div className="mb-1">
                      <span>User reviews score: 4.92 / 5</span>
                      <span style={{ marginLeft: 10 }}>
                        Votes: <span itemProp="votes">3243</span>
                      </span>
                      <span className={style.ratingStars}></span>
                    </div>
                    <meta itemProp="bestRating" content="5" />
                    <meta itemProp="ratingValue" content="4.92" />
                    <meta itemProp="ratingCount" content="1243" />
                    <meta
                      itemProp="itemReviewed"
                      content="NursingExamPro.com"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
